/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchRetry, fetchWithCache } from "./utils";

const centraTopCats: CentraCategory[] = [
  {
    category: "799",
    uri: "brands/",
    name: ["Brands"],
    sortOrder: 0,
  },
  {
    category: "1254",
    uri: "furniturenew/",
    name: ["Furniture"],
    sortOrder: 0,
  },
  {
    category: "1313",
    uri: "lightingnew/",
    name: ["Lighting"],
    sortOrder: 0,
  },
  {
    category: "1287",
    uri: "objectsnew/",
    name: ["Objects"],
    sortOrder: 0,
  },
  {
    category: "1290",
    uri: "mattornew/",
    name: ["Rugs"],
    sortOrder: 0,
  },
  {
    category: "1291",
    uri: "kitchen-detailsnew/",
    name: ["The Kitchen"],
    sortOrder: 0,
  },
];

const centraTopCatIds = centraTopCats.map((c) => c.category);

export function getCanonicalProductCat(
  product: CentraProduct,
  prioCatId?: string
): CentraCategory | undefined {
  if (!product.categories) {
    return;
  }

  const productCats = Object.values(product.categories);
  const cats = productCats.filter((c) => !/^(shop-all|brands)/.test(c.uri));

  if (prioCatId) {
    const prioCat = cats.find((c) => c.category === prioCatId);

    if (prioCat) {
      return prioCat;
    }
  }

  const topCategories = cats.filter((c) =>
    centraTopCatIds.includes(c.category)
  );
  const subCategories = cats
    // Filter only categories that have a parent
    .filter((c) => !centraTopCatIds.includes(c.category) && c.uri.includes("/"))
    .sort((a, b) => b.uri.length - a.uri.length);
  const brandCategories = productCats.filter((c) => /^brands\//.test(c.uri));

  if (product.category) {
    const relevantCategories = [...subCategories, ...topCategories];

    const canonicalCat = relevantCategories.find(
      (c) => c.category == product.category
    );

    if (canonicalCat) {
      // Only return canonicalCategory if it's within the predetermined
      // category structure
      for (const topCat of centraTopCats) {
        if (canonicalCat.uri.startsWith(topCat.uri)) {
          return canonicalCat;
        }
      }
    }
  }

  // Prioritize subcategories by placing them first in the array, but don't
  // disallow top categories or brand categories altogether
  const relevantCategories = [
    ...subCategories,
    ...topCategories,
    ...brandCategories,
  ];

  for (const cat of relevantCategories) {
    for (const topCat of centraTopCats) {
      if (cat.uri.startsWith(topCat.uri)) {
        return cat;
      }
    }
  }

  if (relevantCategories[0]) {
    return relevantCategories[0];
  }
}

export function getProductBrandCat(product: CentraProduct): CentraCategory {
  const productCats = Object.values(product.categories);
  const brandCategories = productCats.filter((c) => /^brands\//.test(c.uri));

  for (const cat of brandCategories) {
    const catArray = cat.uri.split('/');

    if (catArray[1]) {
      const brandName = catArray[1];
      if (product.brandUri.replace(/-/g, "") === brandName.replace(/-/g, "")) {
        return cat;
      }
    }
  }

  for (const cat of brandCategories) {
    for (const topCat of centraTopCats) {
      if (cat.uri.startsWith(topCat.uri)) {
        return cat;
      }
    }
  }

  if (product.brandUri) {
    return {
      category: "",
      uri: `brands/${product.brandUri}`,
      name: [product.brandName],
      sortOrder: 0,
    };
  }
}

export async function fetchProductData(
  centraProduct: CentraProduct,
  locale: string
) {
  const centraId = centraProduct.product;
  const uriSEK = `centraproduct://${centraId}/${locale}/SEK`;
  const uriEUR = `centraproduct://${centraId}/${locale}/EUR`;
  
  const swrFallback = { [uriSEK]: { product: centraProduct }, [uriEUR]: { product: centraProduct } };

  return {
    swrFallback,
  };
}

export async function centraGet(url: string, token?: string) {
  let res: any;

  if (token) {
    res = await fetchRetry(url, {
      headers: {
        accept: `*/*; api-token=${token}`,
      },
    });
  } else {
    res = await fetchRetry(url);
  }

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`${res.status}`);
  }
}

export async function centraGetWithCache(url: string, token?: string) {
  return await fetchWithCache(url, {
    headers: {
      accept: `*/*; api-token=${token}`,
    },
  });
}

export async function centraPost(
  url: string,
  token?: string,
  body?: any,
  signal?: AbortSignal
) {
  const res = await fetchRetry(url, {
    method: "POST",
    signal,
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });

  const resultJson = await res.json();

  if (res.ok) {
    return resultJson;
  } else {
    throw new Error(JSON.stringify(resultJson));
  }
}

export async function centraPostWithCache(
  url: string,
  token?: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "POST",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
}

export async function centraPostWithAuthAndCache(
  url: string,
  token?: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "POST",
    headers: {
      accept: `*/*; api-token=${token}`,
      authorization: `Bearer ${process.env.CENTRA_SHARED_SECRET}`,
      "content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
}

export async function centraPut(url: string, token: string, body?: any) {
  const res = await fetchRetry(url, {
    method: "PUT",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`${res.status}`);
  }
}

export async function centraPutWithCache(
  url: string,
  token: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "PUT",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(body),
  });
}

type StockData = {
  stock: number;
};

export async function centraGetStock(uri: string): Promise<StockData> {
  const [, productId] = /^centrastock:\/\/(\d+)$/.exec(uri);

  const res = await fetchRetry("/api/centra-stock/" + productId);

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`${res.status}`);
  }
}

export async function centraGetProduct(
  uri: string,
  token?: string,
  signal?: AbortSignal
): Promise<CentraProductApiData> {
  const [, productId, language, currency] = /^centraproduct:\/\/(\d+)\/(\w+)\/(\w+)$/.exec(uri);

  if (typeof window === "undefined") {
    throw new Error("`centraGetProduct` can only run on the client");
  }

  try {
    const res = await fetchRetry("/api/centra-product/" + productId, {
      method: "POST",
      body: JSON.stringify({ language, currency, token }),
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    });

    if (res.ok) {
      const data = await res.json();
      return data; // Return the JSON data
    } else {
      throw new Error(`Error: ${res.status} ${res.statusText}`);
    }
  } catch (error) {
    console.error("Failed to fetch product data:", error);
    throw error;
  }
}
